import dayjs from "dayjs";
import dayjsUTC from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import UTC from "dayjs/plugin/utc";
dayjs.extend(relativeTime);
dayjsUTC.extend(UTC);

export const hashStringToColor = (str: string) => {
	const hash = _djb2(str);
	const r = (hash & 0xff0000) >> 16;
	const g = (hash & 0x00ff00) >> 8;
	const b = hash & 0x0000ff;
	return (
		"#" +
		("0" + r.toString(16)).substr(-2) +
		("0" + g.toString(16)).substr(-2) +
		("0" + b.toString(16)).substr(-2)
	);
};

const _djb2 = (str: string) => {
	let hash = 5381;
	for (let i = 0; i < str.length; i++) {
		hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
	}
	return hash;
};

export const formatTime = (pastTimestamp: number) => {
	//if time is greater than 1 day, show the date and time
	if (dayjs().diff(pastTimestamp, "day") > 1) {
		return dayjs(pastTimestamp).format("MMM D, YYYY h:mm A");
	}
	return dayjs(pastTimestamp).fromNow();
};

export const durationInDays = (startDate: Date | undefined, endDate: Date | undefined) => {
	if (!startDate || !endDate) {
		return 0;
	}

	const start = dayjs(startDate);
	const end = dayjs(endDate);
	return end.diff(start, "day");
};

export const getUTCTime = (date: Date | undefined) => {
	if (!date) {
		return "";
	}
	return dayjsUTC(date).utc().format("HH:mm");
};
