import { NetworkType } from "@/types/network-type";
import { TokenType } from "@/types/token-type";
import { hashStringToColor } from "@/utils/formatters";
import Image from "next/image";
import { Badge } from "../components/badge";
import { NetworkIcon } from "./network-icon";

type TokenIconProps = {
	currency: TokenType | undefined;
	height?: number;
	width?: number;
	network?: NetworkType;
};

export const TokenIcon = ({ currency, height = 40, width = 40, network }: TokenIconProps) => {
	return (
		<>
			{network ? (
				<Badge
					position="bottom-right"
					badgeContent={<NetworkIcon width={width / 2} height={height / 2} network={network} />}>
					<_Token currency={currency} height={height} width={width} />
				</Badge>
			) : (
				<_Token currency={currency} height={height} width={width} />
			)}
		</>
	);
};

const _Token = ({ currency, height = 40, width = 40 }: Partial<TokenIconProps>) => {
	return (
		<>
			{currency?.logoURI ? (
				<Image src={currency?.logoURI} alt="" className="rounded-full" height={height} width={width} />
			) : (
				<div
					className="text-xs font-bold rounded-full flex items-center justify-center"
					style={{
						width: `${width}px`,
						height: `${height}px`,
						background: hashStringToColor(`${currency?.symbol} ${currency?.name}` ?? "??"),
					}}>
					<div>{currency?.symbol?.substring(0, 2) ?? "🧐"}</div>
				</div>
			)}
		</>
	);
};
