"use client";

import * as DialogPrimitive from "@radix-ui/react-dialog";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "@/lib/utils";

const dialogVariants = cva(
	"duration-200 rabbet-clip data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
	{
		variants: {
			variant: {
				default:
					"bottom-0 md:bottom-[unset] fixed left-[50%] md:top-[50%] z-50 grid w-full md:max-w-lg translate-x-[-50%] md:translate-y-[-50%] gap-4 bg-dialog-content pt-4 px-2 pb-2 shadow-lg md:w-full data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-bottom-[48%] md:data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-bottom-[48%] md:data-[state=open]:slide-in-from-top-[48%]",
				opaque: "px-4 fixed z-50 top-4 grid w-full max-w-xl",
				mobile:
					"bottom-0 fixed left-[50%] z-50 grid w-full gap-4 bg-dialog-content pt-4 px-2 pb-2 shadow-lg translate-x-[-50%] data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-bottom-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-bottom-[48%]",
				desktop:
					"bottom-[unset] fixed top-[50%] z-50 grid w-full max-w-lg translate-y-[-50%] gap-4 bg-dialog-content p-2 shadow-lg data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-bottom-[48%] data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-bottom-[48%] data-[state=open]:slide-in-from-top-[48%]",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	}
);

const dialogOverlayVariants = cva(
	"fixed inset-0 z-50 transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in",
	{
		variants: {
			variant: {
				default: "bg-black/10 backdrop-blur-sm",
				desktop: "bg-black/10 backdrop-blur-sm",
				opaque: "bg-gray-100 dark:bg-slate-900",
				mobile: "bg-black/10 backdrop-blur-sm",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	}
);

const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogClose = DialogPrimitive.Close;

const DialogPortal = ({ children, ...props }: DialogPrimitive.DialogPortalProps) => (
	<DialogPrimitive.Portal {...props}>
		<div className="fixed inset-0 z-50 flex items-start max-h-[500px] overflow-y-auto overflow-x-hidden justify-center sm:items-center">
			{children}
		</div>
	</DialogPrimitive.Portal>
);
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

interface DialogOverlay
	extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>,
		VariantProps<typeof dialogOverlayVariants> {}

const DialogOverlay = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Overlay>, DialogOverlay>(
	({ className, variant, ...props }, ref) => (
		<DialogPrimitive.Overlay ref={ref} className={dialogOverlayVariants({ variant, className })} {...props} />
	)
);
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

interface DialogContentProps
	extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>,
		VariantProps<typeof dialogVariants> {
	hideClose?: boolean;
	overlayClassName?: string;
}

const DialogContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, DialogContentProps>(
	({ className, overlayClassName, hideClose: _hideClose = false, variant, children, ...props }, ref) => (
		<DialogPortal>
			<DialogOverlay className={cn(overlayClassName)} variant={variant} />
			<DialogPrimitive.Content ref={ref} className={cn(dialogVariants({ variant, className }))} {...props}>
				{children}
			</DialogPrimitive.Content>
		</DialogPortal>
	)
);
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={cn("flex flex-col space-y-1.5 px-3 text-center sm:text-left", className)} {...props} />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)}
		{...props}
	/>
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Title
		ref={ref}
		className={cn("text-lg font-semibold leading-none tracking-tight mr-[64px]", className)}
		{...props}
	/>
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
	<DialogPrimitive.Description
		ref={ref}
		className={cn("text-sm text-muted-foreground mr-[64px]", className)}
		{...props}
	/>
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

const DialogBody = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={cn("flex items-center justify-between bg-dialog-body p-6", className)} {...props} />
);
DialogBody.displayName = "DialogBody";

export {
	Dialog,
	DialogTrigger,
	DialogClose,
	DialogPortal,
	DialogOverlay,
	DialogContent,
	DialogHeader,
	DialogFooter,
	DialogTitle,
	DialogDescription,
	DialogBody,
};
