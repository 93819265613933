import { cn } from "@/lib/utils";
import Link from "next/link";
import { AnchorHTMLAttributes, FC } from "react";

const LinkInternal = Link;
const LinkExternal: FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
	target = "_blank",
	rel = "noopener noreferrer",
	...props
}) => {
	return (
		<a
			{...props}
			target={target}
			rel={rel}
			className={cn(props.className, "cursor-pointer hover:underline")}
		/>
	);
};

export { LinkExternal, LinkInternal };
